'use client';

declare const tolt_referral: string | undefined;
declare const tolt: any | undefined;

import { EventProperties } from '@segment/analytics-next';
import { useFlag } from '@unleash/nextjs';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { useContext, useEffect } from 'react';
import { AnalyticsContext } from './analytics';
import { AuthEvent } from './types';

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
  const result = useContext(AnalyticsContext);
  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result;
};

export const useIdentifyUser = () => {
  const analytics = useAnalytics();

  return async () => {
    try {
      const userAttributes = await fetchUserAttributes();
      await analytics.identifyUser(userAttributes);
    } catch (e) {
      console.error('User identification failed', e);
      throw e;
    }
  };
};

export const useTrackUserAuth = () => {
  const analytics = useAnalytics();

  return async (params: EventProperties) => {
    try {
      const resp = await fetch('/api/user/me', { method: 'PUT' });

      if (resp.ok) {
        const authData = await resp.json();
        const authEvent = authData.isNewUser ? AuthEvent.SIGNUP : AuthEvent.LOGIN;

        await analytics.trackAuth(authEvent, params);
        if (typeof tolt_referral === 'string') {
          tolt.signup(authData.user.email);
        }
      } else {
        console.error(resp);
      }
    } catch (e) {
      console.error('User authentication failed', e);
      throw e;
    }
  };
};

export const useFlagWithAnalytics = (flagName: string) => {
  const analytics = useAnalytics();

  const flag = useFlag(flagName);

  useEffect(() => {
    analytics.trackExperiment(flagName, flag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag]);

  return flag;
};
