'use client';

import { useEffect } from 'react';
import { useAnalytics } from '#/packages/analytics';
import { ErrorPage } from '../components/ErrorPage';

export default function Error() {
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.track('error', {
      message: 'Something went wrong'
    });
  }, []);

  return (
    <ErrorPage
      title={'Something went wrong'}
      subtitle={`Please try to reload the page. We're working on that problem.`}
      status={500}
    />
  );
}
